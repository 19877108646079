import { useState } from 'react'
import Select, { type SelectOption } from './Select'
import CloseIcon from './CloseIcon'

const notEligibleMessage = (
  <>
    <div className="p-4">
      <p className="mb-3">
        Unfortunately, based on your responses, it seems that a call may not be
        the best fit at this time.
      </p>

      <p className="mb-3">
        However, we encourage you to keep exploring our lab and the tools we
        continue to create.
      </p>

      <p className="mb-3">
        Feel free to come back and play with our tools as often as you like;
        it's completely free!
      </p>

      <p className="mb-3">
        When you're ready and in a position to implement an AI solution, and
        your timeline for AI implementation is within the next six months,
        please revisit our site and schedule a call.
      </p>

      <p>
        We look forward to the possibility of working together in the future!
      </p>
    </div>
  </>
)

const roleOptions: SelectOption[] = [
  { value: '', label: 'Select your role' },
  { value: 'C-Level Executive or Owner', label: 'C-Level Executive or Owner' },
  {
    value: 'Vice President or Senior Executive',
    label: 'Vice President or Senior Executive',
  },
  { value: 'Manager or Director', label: 'Manager or Director' },
  {
    value: 'Operations or Administrative Support',
    label: 'Operations or Administrative Support',
  },
]

const decisionMakerOptions: SelectOption[] = [
  { value: '', label: 'Select an option' },
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
]

const implementationTimeOptions: SelectOption[] = [
  { value: '', label: 'Select a timeframe' },
  { value: 'Within 3 months', label: 'Within 3 months' },
  { value: '3-6 months', label: '3-6 months' },
  { value: 'More than 6 months', label: 'More than 6 months' },
]

const isEligibleForCall = (
  role: string,
  decisionMaker: string,
  implementationTime: string
): boolean => {
  return (
    role === 'C-Level Executive or Owner' &&
    decisionMaker === 'Yes' &&
    implementationTime !== '' &&
    implementationTime !== 'More than 6 months'
  )
}

const ScheduleCallPopup = () => {
  const [role, setRole] = useState<string>('')
  const [decisionMaker, setDecisionMaker] = useState<string>('')
  const [implementationTime, setImplementationTime] = useState<string>('')
  const [eligible, setEligible] = useState<boolean>(true)

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (isEligibleForCall(role, decisionMaker, implementationTime)) {
      window.open(
        'https://scheduler.zoom.us/rico-dinolfi/boonevoyage',
        '_blank'
      )
    } else {
      setEligible(false)
    }
  }

  return (
    <div
      id="schedule-call-popup"
      className="fixed hidden top-0 left-0 w-full h-full bg-black bg-opacity-50 items-center justify-center z-50"
    >
      <form
        onSubmit={handleSubmit}
        className="relative bg-gray-100 rounded-lg mt-3  lg:mt-0 p-4 pt-6 mx-8 lg:w-[500px] lg:h-[570px] flex flex-col w-full"
      >
        <div className="flex flex-row w-full mb-4">
          <h2 className="text-2xl font-semibold px-4 text-left w-full">
            {eligible ? 'Get in Touch' : 'Sorry'}
          </h2>
          <button
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              document
                .getElementById('schedule-call-popup')
                ?.classList.add('hidden')
            }}
            className="w-8 h-8"
          >
            <CloseIcon />
          </button>
        </div>
        {!eligible ? (
          notEligibleMessage
        ) : (
          <>
            <p className="text-gray-500 px-4 ">
              Please answer the following questions to schedule a call with our
              team at Boone Voyage.
              <br />
            </p>
            <div className="flex flex-col space-y-4 px-4">
              <label className="flex flex-col pt-4">
                <span className="mb-2">
                  What best describes your role in the company?
                </span>
                <Select value={role} onChange={setRole} options={roleOptions} />
              </label>
              <label className="flex flex-col pt-4">
                <span className="mb-2">
                  Are you the decision-maker for implementing new technologies
                  in your business?
                </span>
                <Select
                  value={decisionMaker}
                  onChange={(nextValue) => setDecisionMaker(nextValue)}
                  options={decisionMakerOptions}
                />
              </label>
              <label className="flex flex-col pt-4">
                <span className="mb-2">
                  When are you planning to make a purchase or investment in
                  software solutions?
                </span>
                <Select
                  value={implementationTime}
                  onChange={(nextValue) => setImplementationTime(nextValue)}
                  options={implementationTimeOptions}
                />
              </label>
            </div>
            <div className="flex pb-8 flex-col md:flex-row w-full px-4 lg:px-0 lg:pt-8 justify-end">
              <button
                type="submit"
                disabled={!role || !decisionMaker || !implementationTime}
                className="bg-green-500 disabled:bg-gray-400 lg:mr-4 text-gray-100 rounded-lg px-4 py-1 w-full lg:max-w-[200px] h-[42px] mt-8 lg:mt-0"
              >
                Schedule a Call
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  )
}

export default ScheduleCallPopup
