const CloseIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'block', margin: 'auto', transform: 'scale(1.2, 1.2)' }}
      viewBox="0 0 24 24"
    >
      <path
        transform="translate(0.5,0)"
        className={color ?? 'fill-gray-700'}
        fillRule="evenodd"
        d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
      />
    </svg>
  )
}

export default CloseIcon
