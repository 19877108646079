import { memo, useCallback } from 'react'

export interface SelectOption {
  value: string
  label: string
}

export interface SelectProps {
  value: string
  onChange: (value: string) => void
  options: SelectOption[]
}

const Select = memo(({ value, onChange, options }: SelectProps) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      e.preventDefault()
      onChange(e.target.value)
    },
    [onChange]
  )

  return (
    <select
      value={value}
      onChange={handleChange}
      className="border rounded-lg p-2"
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  )
})

export default Select
